export function checkAttentive(e: any, button: any) {
  const __attentive = typeof window !== 'undefined' ? (window as any).__attentive : null;
  /* eslint-disable camelcase */
  const attentive_trigger =
    button && Object.prototype.hasOwnProperty.call(button, 'attentive_trigger') ? button.attentive_trigger : null;
  const enabled = attentive_trigger?.enabled || false;
  const desktop_creative_id = attentive_trigger?.desktop_creative_id;
  const mobile_creative_id = attentive_trigger?.mobile_creative_id;
  const creative_id =
    typeof window === 'undefined' ? null : window.innerWidth >= 760 ? desktop_creative_id : mobile_creative_id;
  if (__attentive && enabled && creative_id) {
    e.stopPropagation();
    e.preventDefault();
    __attentive.trigger(null, null, null, +creative_id);
    /* eslint-enable camelcase */
  }
}
